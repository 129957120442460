/**
 * @file
 * Test data and test helper functions.
 */
import { Station, VaqInfo, VoieAQuai } from './station';
import { GovColorMode, EquilibreDto, Train, TransportationPlanStatus } from './transportation-plan';
import { GovInfra } from './gov-infra';
import { GovDataOptions, GovData } from './gov-data';
import { ConflictDto, createConflictFromDto, ConflictCategory, StatutConflict } from './conflict';
import { createEquilibreFromDto, Equilibre } from './equilibre';
import { TravauxOpDto, createTravauxOpFromDto } from './travaux-op';
import { eqGroupGetId } from './eq-utils';

//
//

/**
 * Equilibre data as declared in unit tests.
 * Meant to be converted into an `EquilibreDto`, and then an `Equilibre`.
 */
export interface EqDataTest {
  id: number;
  position?: number;
  typeMateriel?: string;
  materielCount?: number;
  // Train Props - Pass the values for the two trains as "tuples"
  trainIds: (number | null)[]; // will be spread to `eq.arrive.id` and `eq.depart.id`
  numeroArr?: (string | null)[]; // will be spread to `eq.arrive.numero` and `eq.depart.numero`
  typeCirculationArr?: (string | null)[]; // will be spread to `eq.arrive.typeCirculation` and `eq.depart.typeCirculation`
  voieEnLigneArr?: (string | null)[]; // will be spread to `eq.arrive.voieDebut` and `eq.depart.voieFin`
  voieAQuai?: string; // will be spread to `eq.arrive.voieFin` and `eq.depart.voieDebut` - NB. Same value for both trains
  dateHeureArr?: (string | null)[]; // will be spread to `eq.arrive.dateHeure` and `eq.depart.dateHeure`
  passeMinuitArr?: boolean[]; // will be spread to `eq.arrive.passeMinuit` and `eq.depart.passeMinuit`
}

/**
 * Conflict data as it is declared in unit tests.
 * Meant to be converted into a `ConflictDto`, and then a `Conflict`.
 */
export interface ConflictDataTest {
  id: number;
  trainIds: number[];
  sousType?: string;
  description?: string;
  category?: ConflictCategory;
  statut?: StatutConflict;
  statutLabel?: string;
}

//
//

/**
 * Helper function to create an `EquilibreDto` object,
 * as would be returned by the backend.
 *
 * @param
 *   opts.trainIds The train ids for the equilibre. Format: [arriveId, departId]
 *                 Specify a null id for a "train isolé", e.g. [null, departId]
 */
export function createEqDto(eqGroupData: EqDataTest) {
  const eqDto: EquilibreDto = {
    id: eqGroupData.id,
    position: eqGroupData.position || 1,
    typeMateriel: eqGroupData.typeMateriel || '',
    materielCount: eqGroupData.materielCount || 1,
  } as EquilibreDto;
  // Add fake trains to the eqDto.
  const [arriveId, departId] = eqGroupData.trainIds;
  const [arriveNumero, departNumero] = eqGroupData.numeroArr || [null, null];
  const [arriveTypeCirculation, departTypeCirculation] = eqGroupData.typeCirculationArr || [null, null];
  const [arriveVoieEnLigne, departVoieEnLigne] = eqGroupData.voieEnLigneArr || [null, null];
  const voieAQuai = eqGroupData.voieAQuai || null;
  const [arriveDateHeure, departDateHeure] = eqGroupData.dateHeureArr || [null, null];
  // TODO 01/09/2023 To rework when the unit tests will be integrated in the pipeline
  //const [arrivePasseMinuit, departPasseMinuit] = eqGroupData.passeMinuitArr || [false, false];
  if (arriveId) {
    eqDto.arrive = {
      id: arriveId,
      numero: arriveNumero,
      typeCirculation: arriveTypeCirculation,
      voieDebut: arriveVoieEnLigne,
      voieFin: voieAQuai,
      dateHeure: arriveDateHeure,
      // TODO 01/09/2023 To rework when the unit tests will be integrated in the pipeline
      // passMinuit: arrivePasseMinuit,
    } as Train;
  }
  if (departId) {
    eqDto.depart = {
      id: departId,
      numero: departNumero,
      typeCirculation: departTypeCirculation,
      voieDebut: voieAQuai,
      voieFin: departVoieEnLigne,
      dateHeure: departDateHeure,
      // TODO 01/09/2023 To rework when the unit tests will be integrated in the pipeline
      // passMinuit: departPasseMinuit,
    } as Train;
  }

  return eqDto;
}

/**
 * Helper function to create a `ConflictDto` object,
 * as would be returned by the backend.
 */
export function createConflictDto(conflictData: ConflictDataTest) {
  const conflict: ConflictDto = {
    identifier: conflictData.id,
    trainIds: conflictData.trainIds,
    sousType: conflictData.sousType || '',
    category: conflictData.category || ConflictCategory.GENERAL,
    description: conflictData.description || '',
    statut: conflictData.statut,
  };

  return conflict;
}

// Helper function to create eqGroups from test data.
export function createEqGroupsFromTestData(opts: {
  eqGroupsData: EqDataTest[][];
  conflictsData: ConflictDataTest[];
  vaqList?: VaqInfo[];
}): Equilibre[][] {
  return opts.eqGroupsData.map(eqGroupData =>
    createEqGroupFromTestData({ eqGroupData, conflictsData: opts.conflictsData, vaqList: opts.vaqList }),
  );
}

// Helper function to create a single eqGroup from test data.
export function createEqGroupFromTestData(opts: {
  eqGroupData: EqDataTest[];
  conflictsData: ConflictDataTest[];
  vaqList?: VaqInfo[];
}): Equilibre[] {
  return opts.eqGroupData.map(eqData => createEqFromTestData({ eqData, conflictsData: opts.conflictsData, vaqList: opts.vaqList }));
}

/**
 * Helper function to create an `Equilibre` object from test data.
 */
export function createEqFromTestData(opts: { eqData: EqDataTest; conflictsData: ConflictDataTest[]; vaqList?: VaqInfo[] }): Equilibre {
  const eqDto = createEqDto(opts.eqData);
  const conflictDtos = opts.conflictsData.map(conflictData => createConflictDto(conflictData));
  return createEquilibreFromDto(eqDto, opts.vaqList || [], [eqDto], conflictDtos); // Note. Create a fake parent eqGroup with only the given eq.
}

/**
 * Helper function to create an `Conflict` object from test data.
 *
 * By default, the `conflict.eqGroups` prop won't be rehydrated
 * unless the `opts.rehydrateEqGroups` flag is set to true.
 */
export function createConflictFromTestData(opts: {
  conflictData: ConflictDataTest;
  eqGroupsData: EqDataTest[][];
  rehydrateEqGroups?: boolean;
  vaqList?: VaqInfo[];
}) {
  const conflictDto = createConflictDto(opts.conflictData);
  const eqGroupDtos = opts.eqGroupsData.map(eqGroupData => eqGroupData.map(eqData => createEqDto(eqData)));
  const conflict = createConflictFromDto(conflictDto, { eqGroupDtos });

  // Rehydrate the `conflict.eqGroups` -- This is necessary for certain operations
  // such as determining the "focus area" for a given conflict.
  const rehydrateEqGroups = opts.rehydrateEqGroups !== undefined ? opts.rehydrateEqGroups : false;
  if (rehydrateEqGroups) {
    const conflictEqGroupDtos = conflict.eqGroupIds.map(eqGroupId =>
      eqGroupDtos.find(eqGroupDto => eqGroupGetId(eqGroupDto) === eqGroupId),
    );
    conflict.eqGroups = conflictEqGroupDtos.map(eqGroupDto =>
      eqGroupDto.map(eqDto => createEquilibreFromDto(eqDto, opts.vaqList, eqGroupDto)),
    );
  }

  return conflict;
}

export interface TravauxOpDataTest {
  id: number;
  typeIndispo: 'CATENAIRE' | 'VOIE';
  nom: string;
  nomsVEG: string[];
  subtypeOperationVEG: 'TOTAL_INDISPO' | 'MISE_BUTOIR' | 'REDUC_LONG_UTILE';
  dateHeureDebut: string;
  dateHeureFin: string;
  direction1?: string;
  longueur1?: string;
  direction2?: string;
  longueur2?: string;
}

/**
 * Helper function to create a `TravauxOp` object from test data.
 */
export function createTravauxOpFromTestData(opts: { travauxOpData: TravauxOpDataTest; vaqList: VoieAQuai[] }) {
  const travauxOpDto: TravauxOpDto = {
    id: opts.travauxOpData.id,
    rid: 0,
    typeTravaux: 'VEG',
    typeIndispo: opts.travauxOpData.typeIndispo,
    nom: opts.travauxOpData.nom,
    nomsVEG: opts.travauxOpData.nomsVEG,
    subtypeOperationVEG: opts.travauxOpData.subtypeOperationVEG,
    dateHeureDebut: opts.travauxOpData.dateHeureDebut,
    dateHeureFin: opts.travauxOpData.dateHeureFin,
    direction1: opts.travauxOpData.direction1 || '',
    longueur1: opts.travauxOpData.longueur1 || '',
    direction2: opts.travauxOpData.direction2 || '',
    longueur2: opts.travauxOpData.longueur2 || '',
    display: true,
  };
  return createTravauxOpFromDto(travauxOpDto, { sortedVaqList: opts.vaqList });
}

/**
 * Helper function to create a mock travauxOp, with only the important props to specify.
 */
export function createMockTravauxOp(opts: { dateHeureDebut: string; dateHeureFin: string; nomsVEG: string[]; vaqList: VoieAQuai[] }) {
  return createTravauxOpFromTestData({
    travauxOpData: {
      // Important travauxOp props for the test
      dateHeureDebut: opts.dateHeureDebut,
      dateHeureFin: opts.dateHeureFin,
      nomsVEG: opts.nomsVEG,
      // Secondary props
      id: 1,
      nom: 'op travaux test',
      typeIndispo: 'VOIE',
      subtypeOperationVEG: 'TOTAL_INDISPO',
    },
    // vaqList is used to locate which VAQs are affected by the travaux op.
    // If passing a VaqInfo[] instead of a VaqList[], the `vaq.longueurDirection1` prop will be missing,
    // but it should not matter for the tests.
    vaqList: opts.vaqList,
  });
}

/**
 * Helper function to create a `GovData` instance.
 *
 * This function will set default values for unimportant params
 * and convert the data for eqGroups and conflicts into DTO objects.
 */
export function createMockGovData(
  govInfra: GovInfra,
  opts: Partial<{
    tpRid: string;
    eqGroupsData: EqDataTest[][];
    conflictsData: ConflictDataTest[];
  }> = {},
) {
  const govDataOptions: GovDataOptions = {
    tpId: 99,
    tpRid: opts.tpRid || '1976-04-20',
    tpDate: opts.tpRid || '1976-04-20',
    parametrageId: 999,
    tpStatus: TransportationPlanStatus.CURRENT,
    tpOrigine: undefined,
    eqGroupDtos: opts.eqGroupsData ? opts.eqGroupsData.map(eqGroupData => eqGroupData.map(eqData => createEqDto(eqData))) : [],
    conflictDtos: opts.conflictsData ? opts.conflictsData.map(conflictData => createConflictDto(conflictData)) : [],
    govInfra,
    codesChantier: [],
    isConception: false,
    userPreferences: undefined,
    filteredGovVaqList: undefined,
  };

  return new GovData(govDataOptions);
}

/**
 * Return a fake station.
 */
export function createMockStation(
  opts: Partial<{
    numPlatforms: number;
    couleurGovTraitRef: GovColorMode.TYPE_MATERIEL | GovColorMode.TYPE_CIRCULATION;
    couleurGovNumeroTrainRef: GovColorMode;
  }> = {},
) {
  // Base props
  const props: Station = {
    nom: 'TestStation',
    code: 'TEST_STATION',
    couleurGovTraitRef: opts.couleurGovTraitRef,
    couleurGovNumeroTrainRef: opts.couleurGovNumeroTrainRef,
    infrastructure: {},
  } as Station;
  const station: Station = { ...props };

  // Create platforms
  const numPlatforms = opts.numPlatforms || 10;
  const listVoieAQuai: any[] = [];
  for (let i = 1; i < numPlatforms + 1; i++) {
    listVoieAQuai.push({ id: i, nom: `Voie ${i}`, positionGOV: i });
  }
  station.infrastructure.listVoieAQuai = listVoieAQuai;
  return station;
}
