declare const require;

export const environment = {
  production: false,
  envName: 'staging',
  VERSION: require('../../package.json').version,
  backendURI: 'https://staging.opengov-sncf.io',
  basic_key: 'crmClient1',
  basic_pass: 'crmSuperSecret',
};
